






























	import { Component, Vue } from 'vue-property-decorator'
	import '@/assets/themes/prism-darcula.css'
	import '@/assets/themes/prism-darcula.css'
	import Prism from "prismjs";
  
	@Component
	export default class IdentifierTypes extends Vue { 
    private version = "v2";

   		mounted() {
			Prism.highlightAll();
		}
	}
